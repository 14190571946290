<!-- 短信模板信息页面（新增、编辑、详情） -->
<template>
  <div>
    <a-modal
      :title="
        isCreate == 0
          ? '新增短信模板'
          : isCreate == 1
          ? '编辑短信模板'
          : '短信模板详情'
      "
      width="60%"
      okText="确定"
      cancelText="取消"
      v-model:visible.sync="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-form ref="templateForm" :model="templateInfo" :rules="rules">
          <a-form-item
            label="模板名称:"
            name="tplName"
            :labelCol="formLayout2X2.labelCol"
            :wrapperCol="formLayout2X2.wrapperCol"
          >
            <a-input
              v-model:value="templateInfo.tplName"
              type="text"
              placeholder="请输入短信模板名称"
              :readonly="isCreate == 2 ? true : false"
            >
              <template v-slot:prefix>
                <EditOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item
            label="模板内容:"
            name="tplContent"
            :labelCol="formLayout2X2.labelCol"
            :wrapperCol="formLayout2X2.wrapperCol"
          >
            <v-md-editor
              v-model="templateInfo.tplContent"
              :mode="isCreate < 2 ? 'edit' : 'preview'"
              height="300px"
            ></v-md-editor>
          </a-form-item>
          <a-form-item
            label="模板类型:"
            name="tplType"
            :labelCol="formLayout2X2.labelCol"
            :wrapperCol="formLayout2X2.wrapperCol"
          >
            <a-radio-group
              v-model:value="templateInfo.tplType"
              :disabled="isCreate == 2 ? true : false"
            >
              <a-radio :value="1">私用</a-radio>
              <a-radio :value="2">共享</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </div>
      <template #closeIcon
        ><SvgIcon iconName="sclose" className="svg201"
      /></template>
      <template #footer>
        <a-button v-show="isCreate < 2" @click="handleCancel">取消</a-button>
        <a-button v-show="isCreate < 2" type="primary" @click="handleOk"
          >确定</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { EditOutlined } from "@ant-design/icons-vue";
import SvgIcon from "@/components/Svgicon";
import { ref, reactive, toRefs, onMounted, defineComponent, watch } from "vue";
import { message } from "ant-design-vue";
import { saveTemplateInfo } from "@/api/message/template";
export default defineComponent({
  name: "CourseForm",
  components: {
    EditOutlined,
    SvgIcon,
  },
  // 接受父组件传递的值
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Number,
      default: 0,
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["saveCallBack", "handleModalVisible"],
  setup(props, context) {
    const templateForm = ref();
    const state = reactive({
      visible: false,
      confirmLoading: false,
      templateInfo: {
        id: null,
        tplName: "",
        tplContent: "",
        tplType: 1,
      },
      formLayout2X2: {
        labelCol: {
          span: 3,
        },
        wrapperCol: {
          span: 19,
        },
      },
    });
    const rules = {
      tplName: [
        { required: true, message: "模板名称不能为空", trigger: "blur" },
      ],
      tplContent: [
        { required: true, message: "模板内容不能为空", trigger: "blur" },
      ],
      tplType: [
        {
          required: true,
          message: "模板类型不能为空",
          type: "number",
          trigger: "blur",
        },
      ],
    };

    onMounted(() => {});

    const handleOk = () => {
      templateForm.value
        .validate()
        .then((success) => {
          if (success) {
            state.confirmLoading = true;
            saveTemplateInfo(state.templateInfo).then((resp) => {
              if (resp) {
                templateForm.value.resetFields();
                setTimeout(() => {
                  context.emit("saveCallBack", resp.code);
                  context.emit("handleModalVisible", false);
                  state.confirmLoading = false;
                }, 1000);
              }
            });
          } else {
            message.error("短信模板添加失败！！！");
            return false;
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    const handleCancel = () => {
      context.emit("handleModalVisible", false);
      templateForm.value.resetFields();
    };
    const refData = toRefs(state);
    watch(
      () => [props.editData, props.modalVisible],
      ([nv1, nv2]) => {
        state.visible = nv2;
        state.templateInfo = nv1;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    return {
      ...refData,
      rules,
      handleOk,
      handleCancel,
      templateForm,
    };
  },
});
</script>
<style lang='scss' scoped>
</style>